import React, { useState } from "react";
import { Divider, Modal } from "antd";
import PurchaseTerms from "./PurchaseTerms";
import { Link } from "react-router-dom";
import AppVersion from "Components/Shared/AppVersion";
import CustomButton from "Components/CustomButton";
import styled from "styled-components";

const LegalAndSupport = ({ data, productData, isMobile = false, customStyle }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const StyledSpan = styled.span`
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: ${customStyle["primary"]?.linkColor};
    cursor: pointer;

    &:hover {
      color: ${customStyle["primary"]?.hoverLinkColor};
      text-decoration: none;
    }
  `;
  const StyledA = styled.a`
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: ${customStyle["primary"]?.linkColor};
    cursor: pointer;

    &:hover {
      color: ${customStyle["primary"]?.hoverLinkColor};
      text-decoration: none;
    }
  `;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="legal-container">
        <Divider dashed />
        <div className="legal">
          Estou ciente de que a SalesPark está a processar este pedido a serviço de{" "}
          <strong>{data?.producer?.name}</strong>, concordo com os{" "}
          <StyledSpan onClick={() => showModal()}>Termos de Compra</StyledSpan>
          {", "}
          confirmo que li e entendi todos os{" "}
          <StyledA href={"https://salespark.io/redirect/?u=general-terms-of-use"} target="_blank" rel="noreferrer">
            Termos e Políticas
          </StyledA>{" "}
          e declaro que sou maior de idade ou estou autorizado e acompanhado por um tutor legal.
          {isMobile && (
            <div style={{ textAlign: "center" }}>
              <AppVersion align={"center"} />
            </div>
          )}
        </div>
      </div>
      <Modal
        width={900}
        className="purchase-terms-modal"
        closable
        title="Termos de Compra"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <CustomButton customStyle={customStyle} type="primary" onClick={() => handleOk()}>
            Fechar
          </CustomButton>
        }
      >
        <PurchaseTerms data={data} productData={productData} customStyle={customStyle} />
      </Modal>
    </>
  );
};

export default LegalAndSupport;
