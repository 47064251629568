import React, { useState, useEffect } from "react";
import { Form, Input, Select, Row, Col, Spin, Alert } from "antd";
import { action_validate_cofidispay_identification_post } from "api/actions";
import SelectPaymentMethodModal from "../../../Shared/SelectPaymentMethodModal";
import { payment_cofidispay_post } from "api/payments";
import CustomButton from "Components/CustomButton";
import { DoubleRightOutlined } from "@ant-design/icons";

import scanningImage from "../../../../assets/images/scanning.gif";

import { order_post } from "api/orders";
import { utmParams } from "utils/helper";

const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmParams();
const env = process.env?.ENVIRONMENT?.toLowerCase() || "development";

const { Option } = Select;
const cofidisPayRequiredFields = {
  name: true,
  email: true,
  gender: true,
  phoneNumber: true,
};

const requiredText = "Campo Obrigatório";

const CofidisPay = ({ productData, data, actionHandler, step, customStyle }) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState({ visible: false });
  const [genderLoading, setGenderLoading] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);
  const [waitForPayment, setWaitForPayment] = useState(false);
  const [showCofidisPayModal, setShowCofidisPayModal] = useState({ visible: false, url: null });

  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      name: data?.identification?.name,
      email: data?.identification?.email,
      phoneNumber: data?.identification?.phone,
    });

    if (data?.identification?.name) {
      validateCofidisPayIdentification(data?.identification);
    }
  }, []);

  const onFinish = (values) => {
    setFormValues(values);
    startCofidisPayTransaction(values);
  };

  const startCofidisPayTransaction = async (values) => {
    if (!values) {
      values = formValues;
    }

    if (data?.identification?.email === undefined || data?.identification?.email === null) {
      return;
    }
    setLoading(true);

    const payload = {
      buyer: data.identification,
      products: data.purchase_products,
      purchase_id: data.purchase_id,
      main_product: data.main_product,
      coupon: data.coupon,
      cofidispay_data: values,
      pathname: window.location.pathname,
      origin: window.location.origin,
      affiliate_id: data.affiliate_id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };

    // Disable hability to change quantity
    actionHandler("qty_locked", true);

    const order = await createOrder({ ...payload, method: "cofidispay", source: "checkout" });
    if (!order.status) {
      setLoading(false);
      setErrorFetching(true);
      return;
    }

    const cofidisPayResponse = await dbPostCofidisPay(payload);
    if (!cofidisPayResponse.status) {
      setLoading(false);
      setErrorFetching(true);
      return;
    }
    const cofidisPay = cofidisPayResponse.data;
    console.log("cofidisPay", cofidisPay);

    if (cofidisPay.purchase_id !== payload.purchase_id || !cofidisPay.paymentURL) {
      setLoading(false);
      setErrorFetching(true);
      return;
    }

    setWaitForPayment(true);
    setErrorFetching(false);

    actionHandler("update_status", {
      action: "wait-for-payment",
      data: {
        method_id: "COFIDISPAY",
        entity: cofidisPay.entity,
        reference: cofidisPay.requestId,
      },
    });

    setLoading(false);

    setTimeout(() => {
      //console.log("cofidisPay", cofidisPay.paymentURL);

      // Redirect to CofidisPay.paymentURL in

      window.location.href = cofidisPay.paymentURL;
    }, 3000);
  };

  const validateCofidisPayIdentification = async (values) => {
    setGenderLoading(true);
    const response = await dbPostValidateCofidisPayIdentification(values);
    if (response.status) {
      const gender = response?.data?.gender;
      if (gender === "MASCULINO" || gender === "FEMININO") {
        form.setFieldsValue({
          gender: gender === "MASCULINO" ? "M" : "F",
        });
      }
    }
    setGenderLoading(false);
  };

  const dbPostValidateCofidisPayIdentification = async (values) => {
    return new Promise((resolve) => {
      try {
        action_validate_cofidispay_identification_post(values)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbPostCofidisPay = async (values) => {
    return new Promise((resolve) => {
      try {
        payment_cofidispay_post(values)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const createOrder = (payload) => {
    return new Promise((resolve) => {
      try {
        order_post(payload)
          .then((res) => resolve({ status: true, data: res }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className={"cofidispay-container"}>
        {isLoading && <div className="loading h-"></div>}
        {errorFetching && !isLoading && (
          <div className="error">
            <Alert
              message="Oops, ocorreu um erro ao efetuar o pedido de pagamento por CofidisPay."
              description="Não foi possível efetuar o pedido de pagamento por CofidisPay para a tua compra. Tenta novamente dentro de alguns segundos."
              type="error"
              showIcon
            />
            <div style={{ textAlign: "center" }}>
              <CustomButton buttonStyle={customStyle} type="link" onClick={() => startCofidisPayTransaction()}>
                Tentar novamente
              </CustomButton>
            </div>
            <div style={{ textAlign: "center" }}>
              <CustomButton
                size="small"
                buttonStyle={customStyle}
                type="outline"
                onClick={() => setSelectPaymentMethod({ visible: true })}
              >
                Selecionar outro método de pagamento
              </CustomButton>
            </div>
          </div>
        )}
        {!errorFetching && !isLoading && !waitForPayment && (
          <Form name="cofidispay" form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              // onBlur={() => validateCofidisPayIdentification(form.getFieldsValue())}
              label="Nome"
              name="name"
              rules={[{ required: cofidisPayRequiredFields["name"], message: requiredText }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: cofidisPayRequiredFields["email"], message: requiredText }]}
            >
              <Input type="email" />
            </Form.Item>

            <Row gutter={20}>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Género"
                  name="gender"
                  rules={[{ required: cofidisPayRequiredFields["gender"], message: requiredText }]}
                >
                  <Select style={{ width: "100%" }} disabled={genderLoading}>
                    <Option value="M">Masculino</Option>
                    <Option value="F">Feminino</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Telemóvel"
                  name="phoneNumber"
                  rules={[{ required: cofidisPayRequiredFields["phoneNumber"], message: requiredText }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <CustomButton
              block
              // className="submit-button"
              disabled={genderLoading}
              htmlType="submit"
            >
              Continuar com Cofidis Pay <DoubleRightOutlined />
              {/* <span id="button-text">
            {genderLoading ? <div className="spinner" id="spinner"></div> : "Continuar com Cofidis Pay"}
          </span> */}
            </CustomButton>
          </Form>
        )}

        {waitForPayment && !errorFetching && (
          <div>
            <div className="payment-loading-title">Pagamento por CofidisPay</div>
            <div className="payment-loading-sub-title">
              Vais ser redirecionado para o site da CofidisPay dentro de alguns segundos...
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
              <div className="payment-loading-image">
                <img width={50} src={scanningImage} alt="A preparar o teu pedido de pagamento por CofidisPay..." />
              </div>
            </div>
          </div>
        )}
      </div>

      <SelectPaymentMethodModal
        data={data}
        actionHandler={actionHandler}
        visible={selectPaymentMethod.visible}
        close={() => setSelectPaymentMethod({ visible: false })}
      />
    </Spin>
  );
};

export default CofidisPay;
