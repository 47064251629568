import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const payment_mbway_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/mbway", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_mb_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/mb", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_check_get = async (purchase_id, method_id, entity, reference) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get("/payments/check/?" + new URLSearchParams({ purchase_id, method_id, entity, reference }))
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_check_get_v2 = async (method_id, payment_unique_id) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get("/payments/check/v2/?" + new URLSearchParams({ method_id, payment_unique_id }))
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_paypal_clientid_get = async (product_id) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`/payments/paypal/clientid/${product_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_stripe_publishable_key_get = async (product_id) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`/payments/stripe/publishable_key/${product_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_paypal_create_intent_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/paypal/intent/create", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_paypal_confirm_intent_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/paypal/intent/confirm", {
        payload,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_stripe_create_intent_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/stripe/intent/create", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_stripe_intent_confirm_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/stripe/intent/confirm", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const retreive_confirm_setup_intent_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/stripe/confirm-setup-intent", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const stripeV4CreatePaymentSession = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/stripe/v4/create-payment-session", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const confirmPaymentIntentV4 = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/stripe/v4/confirm-payment-intent", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const payment_cofidispay_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/payments/cofidispay", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};
