import React, { useState, useF, useEffect } from "react";
import { Button, Collapse, Input, Tooltip, notification } from "antd";
import { CaretRightOutlined, CheckCircleFilled, CloseCircleTwoTone } from "@ant-design/icons";
import { coupons_post } from "api/coupons";
import CustomButton from "Components/CustomButton";

const { Panel } = Collapse;

// const dbCouponsPost = (code, body) => {
//   return new Promise((resolve) => {
//     try {
//       coupons_post(code, body)
//         .then((res) => resolve({ status: true, data: res.data }))
//         .catch((err) => {
//           resolve({ status: false, data: err });
//         });
//     } catch (error) {
//       resolve({ status: false, data: error });
//     }
//   });
// };

// const validateCoupon = async (couponCode, data, actionHandler, setIsLoading) => {
//   setIsLoading(true);

//   const couponResponse = await dbCouponsPost(couponCode, {
//     identification: data.identification,
//     main_product: data.main_product,
//     price_options: data.price_options,
//     purchase_id: data.purchase_id,
//     purchase_products: data.purchase_products,
//   });
//   if (!couponResponse.status) {
//     notification.warning({
//       message: "Não foi possível validar o cupão",
//       description: "Ocorreu um erro ao validar o cupão. Tenta novamente mais tarde.",
//     });
//     setIsLoading(false);
//     return;
//   }

//   const couponData = couponResponse.data;

//   if (!couponData.status) {
//     notification.warning({
//       message: "Oops!",
//       description: couponData.message,
//     });
//     setIsLoading(false);
//     return;
//   }

//   actionHandler("coupon", couponData.data);
//   setIsLoading(false);
// };

const submitCoupon = async (couponCode, data, actionHandler, setIsLoading) => {
  if (!couponCode || couponCode === "") {
    notification.warning({
      message: "Falta o código do cupão",
      description: "Insere o código do cupão para aplicar o desconto",
    });
    return;
  }

  // validateCoupon(couponCode, data, actionHandler, setIsLoading);

  actionHandler("coupon", couponCode);
};

const InsertCoupon = ({ data, actionHandler, couponCode, isBusy, isLoading, setCouponCode, setIsLoading }) => {
  const validCoupon = data?.coupon?.coupon_code && data?.coupon?.active === true;
  if (validCoupon) couponCode = data?.coupon?.coupon_code;

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <Input
          className={`form-item-coupon ${validCoupon ? "coupon-active" : ""}`}
          value={couponCode}
          readOnly={validCoupon}
          disabled={isBusy}
          placeholder="Insere o teu cupão de desconto"
          onChange={(e) => setCouponCode(e.target.value)}
          suffix={
            validCoupon && (
              <Tooltip title="Cupão aplicado">
                <CheckCircleFilled style={{ color: "#52c41a" }} />
              </Tooltip>
            )
          }
        />
        {validCoupon ? (
          <Button
            className="coupon-button-remove"
            danger
            onClick={(e) => {
              e.preventDefault();
              actionHandler("remove-coupon", null);
              setCouponCode("");
            }}
          >
            <CloseCircleTwoTone twoToneColor={"#f5222d"} /> Remover
          </Button>
        ) : (
          <CustomButton
            addClass="coupon-button"
            htmlType="button"
            loading={isLoading}
            disabled={isBusy || validCoupon}
            onClick={() => submitCoupon(couponCode, data, actionHandler, setIsLoading)}
            type="outline"
          >
            Aplicar
          </CustomButton>
        )}
      </div>
    </>
  );
};

const panelText = (data) => {
  if (data.coupon?.active === true) {
    return (
      <div>
        <CheckCircleFilled style={{ color: "#52c41a" }} /> Cupão <strong>{data.coupon?.coupon_code}</strong>{" "}
        aplicado!
      </div>
    );
  }

  return <>Tens um cupão de desconto? Clica aqui</>;
};

const isBusy = (isLoading, disabled) => {
  if (isLoading === true) return true;
  if (disabled === true) return true;
  return false;
};
const Coupon = ({ show = false, disabled, data, actionHandler }) => {
  const [couponCode, setCouponCode] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data?.coupon?.isLoading === true) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (data?.coupon?.coupon_code !== null && data !== "") {
      setCouponCode(data?.coupon?.coupon_code);
    }

    if (data?.coupon?.coupon_code === "") {
      setCouponCode("");
    }
  }, [data, isLoading, disabled]);

  if (!data) return <></>;

  const statusAction = data?.status?.action;
  const isDisabled = statusAction === "paid" || statusAction === "wait-for-payment";
  if (isDisabled && !couponCode) return <></>;
  if (isDisabled && couponCode)
    return (
      <div>
        Cód. do Cupão: <strong>{couponCode}</strong>
      </div>
    );
  if (show)
    return (
      <InsertCoupon
        data={data}
        actionHandler={actionHandler}
        couponCode={couponCode}
        isBusy={isBusy(isLoading, disabled)}
        isLoading={isLoading}
        setCouponCode={setCouponCode}
        setIsLoading={setIsLoading}
      />
    );

  if (!show) {
    return (
      <Collapse
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
      >
        <Panel header={panelText(data)} key="1" className="site-collapse-custom-panel">
          <InsertCoupon
            data={data}
            actionHandler={actionHandler}
            couponCode={couponCode}
            isBusy={isBusy(isLoading, disabled)}
            isLoading={isLoading}
            setCouponCode={setCouponCode}
            setIsLoading={setIsLoading}
          />
        </Panel>
      </Collapse>
    );
  }
};

export default Coupon;
