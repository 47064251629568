import React from "react";
import { Button } from "antd";

const CustomButton = ({ type = "primary", addClass, customRef, buttonStyle, children, ...props }) => {
  const defaultStyles = {
    primary: {
      backgroundColor: "#21ba45",
      borderColor: "#44bd60",
      color: "#fff",
      hoverBgColor: "#16ab39",
      hoverBorderColor: "#44bd60",
      hoverColor: "#fff",
    },
    link: {
      linkColor: "#21ba45",
      hoverLinkColor: "#21ba45",
    },
    outline: {
      backgroundColor: "transparent",
      borderColor: "#21ba45",
      color: "#21ba45",
      hoverBgColor: "#21ba45",
      hoverBorderColor: "#21ba45",
      hoverColor: "#fff",
    },
  };

  // SalesPark Colors
  // const defaultStyles = {
  //   primary: {
  //     backgroundColor: "#EE6B4C",
  //     borderColor: "#EE6B4C",
  //     color: "#fff",
  //     hoverBgColor: "#D95A3E",
  //     hoverBorderColor: "#D95A3E",
  //     hoverColor: "#fff",
  //   },
  //   outline: {
  //     backgroundColor: "transparent",
  //     borderColor: "#EE6B4C",
  //     color: "#EE6B4C",
  //     hoverBgColor: "#EE6B4C",
  //     hoverBorderColor: "#EE6B4C",
  //     hoverColor: "#fff",
  //   },
  // };

  let style = defaultStyles[type];

  if (!style) style = defaultStyles["primary"];

  // If ButtonStyle is passed, it will override the default style
  if (buttonStyle) {
    style = buttonStyle[type];
  }

  if (type === "link") {
    return (
      <div
        style={{
          "--button-link-color": style.linkColor,
          "--button-hover-link-color": style.hoverLinkColor,
        }}
      >
        <style>
          {`
            .salespark-custom-link-button {
              color: var(--button-link-color) !important;
            }
            .salespark-custom-link-button:hover {
              color: var(--button-hover-link-color) !important;  
            }
          `}
        </style>
        <Button type="link" ref={customRef} className={`${addClass} salespark-custom-link-button`} {...props}>
          {children}
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        "--button-bg": style.backgroundColor,
        "--button-border": style.borderColor,
        "--button-color": style.color,
        "--button-hover-bg": style.hoverBgColor,
        "--button-hover-border": style.hoverBorderColor,
        "--button-hover-color": style.hoverColor,
      }}
    >
      <style>
        {`
          .salespark-custom-button {
            background-color: var(--button-bg) !important;
            border-color: var(--button-border) !important;
            color: var(--button-color) !important;
          }
          .salespark-custom-button:hover {
            background-color: var(--button-hover-bg) !important;
            border-color: var(--button-hover-border) !important;
            color: var(--button-hover-color) !important;  
          }
          .salespark-custom-button::selection{
            background-color: var(--button-bg) !important;
          }
        `}
      </style>
      <Button ref={customRef} className={`${addClass} salespark-custom-button submit-button`} {...props}>
        {children}
      </Button>
    </div>
  );
};

export default CustomButton;
