import React, { useEffect, useState } from "react";
import { Select, Typography, Tooltip, Card, Divider } from "antd";
import { formatCurrency, createPricingOptions, sumPurchaseProdutcs } from "../../utils/helper";
import useMediaQuery from "use-media-antd-query";
import ExternalManagement from "./ExternalManagementInfo";
import OtherProducts from "./OtherProducts";
import { totalPaymentTooltip, totalPayment, totalPriceInstallmentsInfo, getOfferData } from "./helper";
import CustomButton from "Components/CustomButton";
const { Text } = Typography;

// const sumPurchaseProdutcs = (purchase_products, onlyMainProduct = false) => {
//   let total = 0;
//   let total_wo_vat = 0;
//   let total_vat = 0;
//   let discount = 0;
//   let full_price = 0;

//   for (const product of purchase_products) {
//     if (onlyMainProduct === true && product.type !== "main") {
//       continue;
//     }
//     const qty = product.qty || 1;
//     total += product.price;
//     if (product.price < product.full_price) {
//       discount = product.full_price - product.price;
//       full_price = product.full_price;
//     }

//     total_wo_vat += product.price_details.price_wo_vat;
//     total_vat += product.price_details.vat_value;

//     total = total * qty;
//     total_wo_vat = total_wo_vat * qty;
//     total_vat = total_vat * qty;
//     discount = discount * qty;
//     full_price = full_price * qty;
//   }

//   return {
//     price: total,
//     price_wo_vat: total_wo_vat,
//     vat_value: total_vat,
//     discount: discount,
//     full_price: full_price,
//   };
// };

const Pricing = ({ productData, data, actionHandler, isMobile, customStyle }) => {
  const colSize = useMediaQuery();
  const [statusAction, setStatusAction] = useState({});
  const [viewDetailedPrices, setViewDetailedPrices] = useState(false);
  // const [grandTotal, setGrandTotal] = useState(sumPurchaseProdutcs(data?.purchase_products));
  const selectedOffer = data?.price_option; // productData?.offers.find((offer) => offer.offer_id === data?.price_option);
  const purchaseProducts = data?.purchase_products;

  const mainProduct = purchaseProducts.find((product) => product.type === "main");
  const otherProducts = purchaseProducts.filter((product) => product.type === "bump");

  const isExternalManagement = productData?.management === "external";
  //console.log("selectedOffer", selectedOffer);
  useEffect(() => {
    setStatusAction(data?.status?.action);
    // setGrandTotal(sumPurchaseProdutcs(data?.purchase_products));
  }, [data]);

  const grandTotal = sumPurchaseProdutcs(data?.purchase_products);
  const mainProductTotal = sumPurchaseProdutcs(data?.purchase_products, true);

  const InstallmentsFrequency = ({ offer, data, hasBumps }) => {
    const product = data?.purchase_products?.find((product) => product.type === "main");
    const interval = offer?.payments_interval;
    const installments = offer?.n_payments;
    const missingInstallments = installments - 1;

    if (interval !== 1 && interval !== null && interval !== undefined) {
      const frequency = product.payments_frequency === "month" ? "meses" : "anos";

      return (
        <div>
          <div>
            <div>{formatCurrency(mainProductTotal?.price)} a cada prestação seguinte</div>
          </div>
          <div>
            Prestação a cada {interval} {frequency}
          </div>
        </div>
      );
    }

    // Fallback
    const frequency = product.payments_frequency === "month" ? "mensal" : "anual";

    return (
      <div>
        {hasBumps ? (
          <div className="small">{formatCurrency(mainProductTotal?.price)} a cada prestação seguinte</div>
        ) : (
          <span>Prestação {frequency}</span>
        )}
      </div>
    );
  };

  const RecurrenceFrequency = ({ offer, data, hasBumps }) => {
    const product = data?.purchase_products?.find((product) => product.type === "main");

    const interval = offer?.recurrence_billing_method;
    const installments = offer?.recurrence_max_payments;
    const missingInstallments = installments - 1;

    // if (interval === "forever") {
    const frequency =
      product.payments_interval === 1
        ? product.payments_frequency === "month"
          ? "mensal"
          : "anual"
        : `a cada ${product.payments_interval} ${product.payments_frequency === "month" ? "meses" : "anos"}`;

    return (
      <div>
        <div>
          <div>
            {formatCurrency(mainProductTotal?.price)} por cada recorrência {frequency}
          </div>
        </div>
      </div>
    );
    // }

    //const frequency = product.payments_frequency === "month" ? "mensal" : "anual";
    // const pluralFrequency = product.payments_frequency === "month" ? "mensais" : "anuais";

    // const frequency =
    //   product.payments_interval === 1
    //     ? product.payments_frequency === "month"
    //       ? "mensal"
    //       : "anual"
    //     : `a cada ${product.payments_interval} ${product.payments_frequency === "month" ? "meses" : "anos"}`;

    // return (
    //   <div>
    //     {hasBumps ? (
    //       <div className="small">
    //         <>
    //           {formatCurrency(mainProductTotal?.price)} a cada recorrência {frequency} seguinte
    //         </>
    //       </div>
    //     ) : (
    //       <span>
    //         {" "}
    //         <>
    //           {formatCurrency(mainProductTotal?.price)} a cada recorrência {frequency} seguinte
    //         </>
    //       </span>
    //     )}
    //   </div>
    // );
  };

  const PriceInfo = ({ offer, data }) => {
    const hasBumps = data.bumps?.length > 0;

    let recurrenceFrequencySingular = "mês";

    const product = data?.purchase_products?.find((product) => product.type === "main");

    if (product.payments_frequency) {
      recurrenceFrequencySingular = product.payments_frequency === "month" ? "mês" : "ano";
      // recurrenceFrequencyPlural = product.payments_frequency === "month" ? "meses" : "anos";
    }

    // **********************************************************************************************************
    // RECURRENCE WITHOUT BUMPS
    // **********************************************************************************************************
    if (offer?.price_method === "recurrence" && hasBumps === false) {
      return (
        // <div className="price">
        //   <div>Total a pagar</div>
        //   <div>
        //     {formatCurrency(grandTotal?.price)} / {recurrenceFrequencySingular}
        //   </div>
        // </div>
        <div>
          <div className="price">
            <div>Total a pagar</div>
            <div>
              <div style={{ textAlign: "right" }}>{formatCurrency(grandTotal?.price)}</div>
            </div>
          </div>
          <div className="installments-info">
            <RecurrenceFrequency offer={offer} data={data} hasBumps={hasBumps} />
          </div>
        </div>
      );
    }

    // **********************************************************************************************************
    // RECURRENCE WITH BUMPS
    // **********************************************************************************************************
    if (offer?.price_method === "recurrence" && hasBumps === true) {
      return (
        // <div className="price">
        //   <div>Total a pagar</div>
        //   <div>
        //     <div style={{ textAlign: "right" }}>{formatCurrency(grandTotal?.price)} hoje</div>
        //   </div>
        //   <div className="small">{formatCurrency(mainProductTotal?.price)} nas recorrências seguintes</div>
        // </div>
        <div>
          <div className="price">
            <div>Total a pagar</div>
            <div>
              <div style={{ textAlign: "right" }}>{formatCurrency(grandTotal?.price)} hoje</div>
            </div>
          </div>
          <div className="installments-info">
            <RecurrenceFrequency offer={offer} data={data} hasBumps={hasBumps} />
          </div>
        </div>
      );
    }

    // **********************************************************************************************************
    // INSTALLMENTS WITHOUT BUMPS
    // **********************************************************************************************************
    if (offer?.price_method === "installments" && hasBumps === false) {
      return (
        <div>
          <div className="price">
            <div>Total a pagar</div>
            <div>
              <div style={{ textAlign: "right" }}>
                {offer.n_payments} x {formatCurrency(grandTotal?.price)}
              </div>
            </div>
          </div>
          <div className="installments-info">
            <InstallmentsFrequency offer={offer} data={data} hasBumps={hasBumps} />
          </div>
        </div>
      );
    }

    // **********************************************************************************************************
    // INSTALLMENTS WITH BUMPS
    // **********************************************************************************************************
    if (offer?.price_method === "installments" && hasBumps === true) {
      return (
        <div>
          <div className="price">
            <div>Total a pagar</div>
            <div>
              <div style={{ textAlign: "right" }}>{formatCurrency(grandTotal?.price)} hoje</div>
            </div>
          </div>
          <div className="installments-info">
            <InstallmentsFrequency offer={offer} data={data} hasBumps={hasBumps} />
          </div>
        </div>
      );
    }

    // Fallback
    return (
      <div>
        <div className="price">
          <div>Total a pagar </div>
          <div>
            {totalPriceInstallmentsInfo(mainProduct, otherProducts)} {formatCurrency(grandTotal?.price)}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="pricing-container">
      {productData?.offers.length > 1 && (
        <div className="price-selector">
          <Select
            disabled={
              statusAction === "prepare-payment" ||
              statusAction === "wait-for-payment" ||
              statusAction === "checkout" ||
              statusAction === "paid"
                ? true
                : false
            }
            showSearch={false}
            value={data?.price_option?.offer_id}
            className="price-select"
            placeholder="Seleciona a forma de pagamento"
            onChange={(e) => actionHandler("price_option", getOfferData(e, productData))}
          >
            {createPricingOptions(productData?.offers)}
          </Select>
        </div>
      )}
      {otherProducts?.length > 0 && (
        <OtherProducts
          mainProduct={mainProduct}
          otherProducts={otherProducts}
          colSize={colSize}
          selectedOffer={selectedOffer}
          viewDetailedPrices={viewDetailedPrices}
          isExternalManagement={isExternalManagement}
        />
      )}
      <div className="grand-pricing">
        <Card className="grand-pricing-card">
          <PriceInfo offer={selectedOffer} data={data} />

          {/* Show only if product isnt external management */}

          <div className="grand-price-details">
            {data?.coupon?.active === true && (
              <>
                <div className="grand-price-detail">
                  <Text className="grand-price-detail-title">Preço Original:</Text>
                  <Text className="grand-price-detail-value">{formatCurrency(grandTotal?.full_price)}</Text>
                </div>
                <div className="grand-price-detail-discount">
                  <Text className="grand-price-detail-title-discount">Cupão de desconto:</Text>
                  <Text className="grand-price-detail-value-discount">
                    {"-"}
                    {formatCurrency(grandTotal?.discount)}
                  </Text>
                </div>
              </>
            )}

            {isExternalManagement === false && isMobile === false && (
              <div className="grand-price-detail">
                <Text className="grand-price-detail-title">PREÇO S/IVA:</Text>
                <Text className="grand-price-detail-value">{formatCurrency(grandTotal?.price_wo_vat)}</Text>
              </div>
            )}

            {isExternalManagement === false && isMobile === false && (
              <div className="grand-price-detail">
                <Text className="grand-price-detail-title">IVA {data?.geo?.region?.tax_percent}%:</Text>
                <Text className="grand-price-detail-value">{formatCurrency(grandTotal?.vat_value)}</Text>
              </div>
            )}

            {/* {colSize !== "xs" && (
              <div className="grand-price-detail">
                <Text className="grand-price-detail-title">TOTAL A PAGAR:</Text>
                <Text className="grand-price-detail-value">{formatCurrency(grandTotal?.price)}</Text>
              </div>
            )} */}

            {selectedOffer.price_method === "installments" &&
              selectedOffer?.n_payments > 1 &&
              otherProducts?.length === 0 && (
                <div className="installments_disclaimer">
                  <Tooltip title={totalPaymentTooltip(selectedOffer)}>
                    <Text className="text">
                      Vais pagar {totalPayment(selectedOffer)} no final de todas as prestações.
                    </Text>
                  </Tooltip>
                </div>
              )}

            {selectedOffer.price_method === "recurrence" &&
              selectedOffer?.recurrence_billing_method === "limited" &&
              selectedOffer?.recurrence_max_payments > 1 && (
                <div className="installments_disclaimer">
                  <Tooltip title={totalPaymentTooltip(selectedOffer)}>
                    <Text className="text">
                      As recorrências terminam após um máximo de {selectedOffer?.recurrence_max_payments}{" "}
                      pagamentos.
                    </Text>
                  </Tooltip>
                </div>
              )}
          </div>

          {/* Show if external management */}
          {isExternalManagement && <ExternalManagement productData={productData} grandTotal={grandTotal} />}

          {/* Show only if product isnt external management */}
          {!isExternalManagement && purchaseProducts?.length > 1 && (
            <div>
              <CustomButton
                buttonStyle={customStyle}
                type="link"
                style={{ margin: 0, padding: 0 }}
                onClick={() => setViewDetailedPrices((value) => !value)}
              >
                <small>{!viewDetailedPrices ? "Ver todos detalhes" : "Esconder detalhes"}</small>
              </CustomButton>
            </div>
          )}
        </Card>
      </div>
      {selectedOffer?.public_name && (
        <div className="pricing-offer-name">
          <small className="text-muted">{selectedOffer?.public_name}</small>
        </div>
      )}
    </div>
  );
};

export default Pricing;
