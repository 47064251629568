import React from "react";
import { Card } from "antd";
import styled from "styled-components";
const Powered = ({ customStyle }) => {
  const StyledA = styled.a`
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: ${customStyle["primary"]?.linkColor};
    cursor: pointer;

    &:hover {
      color: ${customStyle["primary"]?.hoverLinkColor};
      text-decoration: underline;
    }
  `;
  return (
    <Card size="small" className="card-powered no-radius-bottom">
      <div className="card-powered-content">
        Powered by{" "}
        <StyledA href={"https://salespark.io/"} target="_blank" rel="noreferrer">
          SalesPark
        </StyledA>
      </div>
    </Card>
  );
};

export default Powered;
