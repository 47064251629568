import React, { useEffect, useState } from "react";
import { Card } from "antd";
import Powered from "./Powered";
import Product from "./Product";
import Producer from "../Shared/Producer";
import Pricing from "./Pricing";
import Version from "./Version";
import Coupon from "../Shared/Coupon";
import useMediaQuery from "use-media-antd-query";
import Quantity from "./Quantity";

const SideBar = ({ productData, data, actionHandler, isMobile, customStyle }) => {
  const colSize = useMediaQuery();

  const isWidget = window?.location?.hash?.substr(1) === "widget";
  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = isMobile && mobileSizes.includes(colSize);
  const isDesktopSize = !isMobile && !mobileSizes.includes(colSize);
  return (
    <>
      {productData && (
        <>
          {isMobileSize || isDesktopSize ? (
            <>
              {!isMobile && <Powered customStyle={customStyle} />}
              <Card
                size="small"
                className={`${
                  isMobileSize ? "mt-10 no-card" : "no-radius-top no-radius-bottom no-border-top no-border-bottom"
                } pricing-card`}
              >
                <Product
                  customStyle={customStyle}
                  productData={productData}
                  producer={data?.producer}
                  isMobile={isMobileSize}
                  isWidget={isWidget}
                />

                <Quantity
                  data={data}
                  actionHandler={actionHandler}
                  isMobile={isMobileSize}
                  customStyle={customStyle}
                />

                <Pricing
                  customStyle={customStyle}
                  productData={productData}
                  data={data}
                  actionHandler={actionHandler}
                  isMobile={isMobileSize}
                  isWidget={isWidget}
                />

                {data?.allow_coupons?.active === true && (
                  <div className="coupon-code">
                    <Coupon data={data} actionHandler={actionHandler} customStyle={customStyle} />
                  </div>
                )}

                {!isWidget && !isMobileSize && (
                  <Producer data={data} isMobile={isMobileSize} isWidget={isWidget} customStyle={customStyle} />
                )}
              </Card>
              {!isMobile && <Version />}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default SideBar;
