import React, { useState, useEffect } from "react";
import { Card, Alert } from "antd";
import StepsHeader from "./StepsHeader";
import Identification from "./Identification";
import Options from "./Options";
import Payment from "./Payment";
import LegalAndSupport from "./LegalAndSupport";
import Producer from "Components/Shared/Producer";
import useMediaQuery from "use-media-antd-query";

const MainContent = ({ productData, step, data, duplicatesSales, actionHandler, customStyle }) => {
  const colSize = useMediaQuery();
  const isWidget = window?.location?.hash?.substr(1) === "widget";
  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);

  useEffect(() => {}, []);

  return (
    <div>
      <StepsHeader step={step} data={data} actionHandler={actionHandler} customStyle={customStyle} />{" "}
      <Card className="main-container-card">
        {step === 0 && <Identification data={data} actionHandler={actionHandler} customStyle={customStyle} />}
        {step > 0 && duplicatesSales > 0 && (
          <Alert
            className="alert-duplicates-sales"
            style={{ marginBottom: "20px" }}
            message={`ATENÇÃO ${data?.identification?.name.toUpperCase() || ""}!`}
            description={
              <>
                <strong>Já existe uma compra PAGA deste produto</strong>. Cerifica-te que queres mesmo efetuar uma
                nova compra!
              </>
            }
            type="warning"
            showIcon
          />
        )}
        {step === 1 && (
          <Options
            customStyle={customStyle}
            step={step}
            productData={productData}
            data={data}
            actionHandler={actionHandler}
          />
        )}
        {step === 2 && (
          <Payment productData={productData} data={data} actionHandler={actionHandler} customStyle={customStyle} />
        )}
        {!isWidget && isMobileSize && (
          <Producer data={data} isMobile={isMobileSize} isWidget={isWidget} customStyle={customStyle} />
        )}
        <LegalAndSupport data={data} productData={productData} isMobile={isMobileSize} customStyle={customStyle} />
      </Card>
    </div>
  );
};

export default MainContent;
