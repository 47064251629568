import React from "react";
import styled from "styled-components";
const PurchaseTerms = ({ data, productData, customStyle }) => {
  const StyledA = styled.a`
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: ${customStyle["primary"]?.linkColor};
    cursor: pointer;

    &:hover {
      color: ${customStyle["primary"]?.hoverLinkColor};
      text-decoration: none;
    }
  `;

  const isUrl = (str) => {
    if (str === null || str === undefined || str === "") return false;

    const pattern = new RegExp("^(http|HTTP)+(s|S)?://[\\w.$()/]+(?:\\.[\\w.$()/]+)*[\\w$()/]+$", "g");
    return !!pattern.test(str);
  };
  return (
    <div className="purchase-terms-container">
      <p>
        Para problemas e d&uacute;vidas sobre a compra, como op&ccedil;&otilde;es de pagamento, acesso ao produto,
        reembolso e assist&ecirc;ncia em geral, por favor,&nbsp;
        <StyledA href="https://salespark.io/redirect/?u=central-ajuda" target="_blank" rel="noopener noreferrer">
          aceda &agrave; nossa Central de Ajuda
        </StyledA>
        .
      </p>
      <p>
        Este produto foi criado por{" "}
        <strong>
          <StyledA href={`mailto:${data?.producer?.email}`}>{data?.producer?.name}</StyledA>
        </strong>
        . Para quest&otilde;es relacionadas especificamente com o conte&uacute;do deste produto, por favor, entre em
        contacto com Produtor Digital atrav&eacute;s{" "}
        <StyledA href={`mailto:${data?.producer?.email}`}>deste link</StyledA>.
      </p>
      <p>
        Esta compra aparecer&aacute; no seu extrato ou fatura do cart&atilde;o como sendo realizada na empresa
        SalesPark Lda. Contudo, salientamos que se trata de uma mera identifica&ccedil;&atilde;o na fatura ou
        extrato do cart&atilde;o, j&aacute; que este produto n&atilde;o foi criado pela SalesPark Lda, n&atilde;o
        tendo esta qualquer responsabilidade pelo seu conte&uacute;do, funcionalidade, caracter&iacute;sticas,
        oferta e publicidade.
      </p>
      <p>
        Caso a compra seja realizada atrav&eacute;s de pagamento em prestações, fica desta forma obrigado ao
        pagamento integral de todas as presta&ccedil;&otilde;es. Em caso de atraso de pagamento de uma ou mais
        presta&ccedil;&otilde;es a SalesPark poder&aacute; iniciar processos de cobran&ccedil;a internos ou por vias
        judiciais de forma a recuperar os valores devidos. Durante o processo de recupera&ccedil;&atilde;o dos
        valores devidos, a SalesPark poder&aacute; aplicar custos administrativos, judiciais e outros custos
        resultantes do processo de cobran&ccedil;a.
      </p>
      {/*<p>
          Caso este Produto Digital esteja dispon&iacute;vel no modo Per&iacute;odo Gr&aacute;tis, esclarece-se que
          tal modo representa um per&iacute;odo de utiliza&ccedil;&atilde;o gratuita. O per&iacute;odo de
          utiliza&ccedil;&atilde;o gratuita (Per&iacute;odo Gr&aacute;tis) ter&aacute; a dura&ccedil;&atilde;o
          indicada pelo Produtor Digital e tem a finalidade de permitir que os interessados experimentem o
          conte&uacute;do digital durante o prazo acima especificado.
        </p> */}
      {/* <p>
          Salienta-se que caso n&atilde;o cancele expressamente o Per&iacute;odo Gr&aacute;tis no fim do
          per&iacute;odo de utiliza&ccedil;&atilde;o gratuita, o Produtor Digital respons&aacute;vel pelo produto
          passar&aacute; a cobrar, automaticamente, o pagamento referente &agrave; assinatura do Produto Digital,
          cuja aquisi&ccedil;&atilde;o ser&aacute; regida pelos presentes Termos de Compra.
        </p> */}
      <p>
        O criador do produto (Produtor Digital(a)/Vendedor(a)) é o único e exclusivo responsável pelo produto
        digital, em todos os aspetos legais. Qualquer questão administrativa ou judicial relativamente ao conteúdo
        do produto adquirido, as suas características, qualidade, funcionalidades, oferta e publicidade, deve ser
        feita diretamente ao(à) Produtor(a) Digital através do e-mail indicado acima ou outro meio de contacto
        disponível.
      </p>
      <p>
        Quando realiza a compra, concorda e aceita de forma integral e ilimitada os Termos de
        Utiliza&ccedil;&atilde;o e a Pol&iacute;tica de Privacidade da Plataforma SalesPark Lda, cuja vers&atilde;o
        completa pode ser acedida atrav&eacute;s&nbsp;
        <StyledA href="https://salespark.io/redirect/?u=central-ajuda" target="_blank" rel="noopener noreferrer">
          deste link
        </StyledA>
        . Portanto, recomenda-se a leitura atenta dos referidos documentos.
      </p>
      <p>
        Há um período de garantia de 15 dias, garantido pelo Produtor, para solicitar o cancelamento da compra no
        caso de algum defeito/inconsistência do produto.
      </p>

      {productData?.management !== "external" && (
        <p>
          O prazo de cancelamento indicado acima entra em vigor a partir da efetivação da compra. Assim, o
          comprador(a) é responsável por verificar, imediatamente, se o acesso ao produto digital adquirido foi
          efetivado. Neste sentido, caso haja algum problema técnico ao aceder ao conteúdo adquirido,e após
          comprovado pelo suporte técnico da SalesPark Lda, o prazo de cancelamento será suspenso até que o problema
          seja resolvido, que voltará a funcionar a partir de onde parou.
        </p>
      )}
      {productData?.management !== "external" && (
        <p>
          O prazo de entrega do produto digital pode variar conforme o meio de pagamento, no entanto não deve
          ultrapassar as 72 horas.
        </p>
      )}
      {productData?.management === "external" && (
        <p>
          O prazo de cancelamento indicado acima entra em vigor a partir da efetivação da compra. Assim, o
          comprador(a) é responsável por verificar se o produto é entregue no prazo entrega máximo definido.
        </p>
      )}
      {productData?.management === "external" && (
        <p>
          O prazo de entrega do produto pode variar conforme o meio de pagamento, no entanto não deve ultrapassar 5
          dias úteis <small>(excepto por motivos de força maior)</small>.
        </p>
      )}
      <p>
        A SalesPark é uma plataforma de distribuição de produtos digitais e operador intermédio de pagamentos, e não
        tem vínculo de emprego/trabalho ou de qualquer outra natureza que não a prestação de serviços de tecnologia
        e distribuição digital com os Produtores Digitais. Desta forma, reitera-se que a SalesPark Lda não responde
        legal e/ou financeiramente pelo produto adquirido, uma vez que o seu conteúdo, funcionalidade, qualidade,
        oferta e publicidade, são da inteira responsabilidade do Produtor Digital que o criou e disponibilizou
        através da plataforma.
      </p>
      <p>
        Qualquer informação adicional sobre o produto deve ser solicitada pelo comprador diretamente Produtor
        Digital após a compra. O comprador receberá um e-mail para contactar o vendedor.
      </p>
      <p>
        Em caso de reembolso o comprador poderá ser reembolsado através de transferência bancária ou estorno no
        cartão de crédito, dependendo do meio de pagamento utilizado no momento da compra. O reembolso pode demorar
        até um máximo de trinta dias para ser realizado caso se trate de transferência bancária, e até 60 dias, caso
        se trate de estorno no cartão de crédito.
      </p>
      <p>
        Os seus dados de contacto, como nome, e-mail, telemóvel, etc., podem ser fornecidos ao vendedor e/ou ao
        parceiro de divulgação do vendedor.
      </p>
      <p>
        Esta compra poderá ser anulada e/ou as comissões de afiliado canceladas caso seja identificado que se trata
        de um afiliado que compra a partir do próprio link de afiliado.
      </p>
      <p>
        Esta compra poderá ser anulada e/ou as comissões de afiliado canceladas caso os dados do Comprador não
        estejam totalmente preenchidos com informações reais sobre o Comprador.
      </p>
      <p>
        Caso a compra seja iniciada por um comprador que já realizou mais de um pedido de reembolso não fundamentado
        pela SalesPark Lda, a compra poderá não ser aprovada.{" "}
      </p>
      <p>
        A conclusão da compra confirma a consciência e a concordância do comprador no que diz respeito aos Termos de
        Compra.
      </p>
      <p>
        Em caso de litígio o comprador pode recorrer à Entidade de Resolução Alternativa de Litígios de consumo
        listada abaixo::
        <br />
        Associa&ccedil;&atilde;o de Arbitragem de Conflitos de Consumo do Distrito de Coimbra
        <br />
        <small style={{ lineHeight: "12px" }}>
          Morada: Avenida Fern&atilde;o de Magalh&atilde;es, 240 &ndash; 1, 3000-172 Coimbra, endere&ccedil;o de
          e-mail: geral@centrodearbitragemdecoimbra.com, website: http://www.centrodearbitragemdecoimbra.com e
          telefone: +351239821690.
        </small>
      </p>
      <p>
        <strong>
          Os pedidos de reembolso implicam a devolução do valor total da compra e nunca um valor parcial. Isto é
          válido para todos os produtos disponibilizados na Plataforma SalesPark Lda pelos Produtores Digitais.
        </strong>
      </p>

      {data?.producer_terms?.active && isUrl(data?.producer_terms?.url) && (
        <p>
          A compra deste produto está sujeita aos termos e condições do produtor, disponível em:{" "}
          <StyledA href={data?.producer_terms?.url} target="_blank" rel="noopener noreferrer">
            {data?.producer_terms?.url}
          </StyledA>
        </p>
      )}
    </div>
  );
};

export default PurchaseTerms;
