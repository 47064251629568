import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const action_check_duplicated_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/check-duplicated", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const action_pre_checkout_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/pre-checkout", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const action_pre_checkout_option_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/pre-checkout/select-payment-method", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const action_check_email_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/check-email-v2", payload) // Using v2 endpoint
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const action_unique_id_get = async () => {
  return new Promise((resolve, reject) => {
    api_request()
      .get("/actions/unique-id")
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const countries_fiscal_get = async () => {
  return new Promise((resolve, reject) => {
    api_request()
      .get("/actions/countries-fiscal")
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const action_meta_events_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/meta-events", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const action_validate_cofidispay_identification_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/validate-cofidispay-identification", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};
