import React, { useState } from "react";
import { Avatar, Alert, Typography, Divider, Modal, Drawer } from "antd";
import { UserOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined } from "@ant-design/icons";
import CustomButton from "Components/CustomButton";
import { IsNullUndefinedEmpty } from "utils/helper";
import styled from "styled-components";
const { Text, Paragraph } = Typography;
const ProducerContacts = ({ producer, customStyle, linkColor }) => {
  try {
    if (!producer) return <></>;

    <style>
      {`
      .salespark-paragraph {
         color: var(--button-color) !important;
         margin-bottom: 8px;
      }
      .salespark-paragraph .ant-typography-copy  {
        color: var(--button-color) !important;
      }
       
    `}
    </style>;

    let supportEmail = producer?.email;

    if (IsNullUndefinedEmpty(supportEmail)) {
      return (
        <Alert
          message="Erro ao carregar dados do produtor"
          description="Por favor, tente novamente mais tarde"
          type="error"
          showIcon
        />
      );
    }

    return (
      <>
        <div className="mb-2" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {producer?.picture ? (
            <Avatar size={32} src={producer.picture} />
          ) : (
            <Avatar size={32} icon={<UserOutlined />} />
          )}
          <div>
            <h3 className="ml-2 mb-0">{producer?.name}</h3>
          </div>
        </div>

        <div style={{ "--salespark-paragraph-anticon": linkColor }}>
          <style>
            {`.salespark-paragraph { margin-bottom: 8px; }.salespark-paragraph .ant-typography-copy  { color: var(--salespark-paragraph-anticon) !important; }`}
          </style>
          <Paragraph
            className="salespark-paragraph"
            copyable={{ tooltips: ["Copiar endereço de e-mail", "Copiado"], text: supportEmail }}
          >
            <MailOutlined /> <strong>Endereço de e-mail: </strong> {supportEmail}
          </Paragraph>
        </div>

        {producer?.support_phone?.length > 5 && (
          <div style={{ "--salespark-paragraph-anticon": linkColor }}>
            <style>
              {`.salespark-paragraph { margin-bottom: 8px; }.salespark-paragraph .ant-typography-copy  { color: var(--salespark-paragraph-anticon) !important; }`}
            </style>
            <Paragraph
              className="salespark-paragraph"
              copyable={{ tooltips: ["Copiar número de telefone", "Copiado"], text: producer.support_phone }}
            >
              <PhoneOutlined /> <strong>Telefone: </strong>
              <a
                rel="noreferrer"
                target="_blank"
                href={`tel:${producer.support_phone}`}
                style={{ color: linkColor }}
              >
                {producer.support_phone.match(/.{1,3}/g).join(" ")}
              </a>
            </Paragraph>
          </div>
        )}

        {producer?.support_whatsapp?.length > 5 && (
          <div style={{ "--salespark-paragraph-anticon": linkColor }}>
            <style>
              {`.salespark-paragraph { margin-bottom: 8px; }.salespark-paragraph .ant-typography-copy  { color: var(--salespark-paragraph-anticon) !important; }`}
            </style>
            <Paragraph
              className="salespark-paragraph"
              copyable={{ tooltips: ["Copiar número de WhatsApp", "Copiado"], text: producer.support_whatsapp }}
            >
              <WhatsAppOutlined /> <strong>WhatsApp: </strong>
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://wa.me/${producer.support_whatsapp?.replace("+", "")}`}
                style={{ color: linkColor }}
              >
                {producer.support_whatsapp.match(/.{1,3}/g).join(" ")}
              </a>
            </Paragraph>
          </div>
        )}
      </>
    );

    // Error handling
  } catch (error) {
    console.error("Error in ProducerContacts: ", error);
    return (
      <Alert
        message="Erro ao carregar dados do produtor"
        description="Por favor, tente novamente mais tarde"
        type="error"
        showIcon
      />
    );
  }
};

const Producer = ({ data, isMobile, customStyle }) => {
  let linkColor = "#1890ff";
  if (customStyle["primary"]?.linkColor) linkColor = customStyle["primary"]?.linkColor;

  const StyledSpan = styled.span`
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: ${customStyle["primary"]?.linkColor};
    cursor: pointer;

    &:hover {
      color: ${customStyle["primary"]?.hoverLinkColor};
      text-decoration: none;
    }
  `;

  const [showProducerModal, setShowProducerModal] = useState(false);

  const Initials = ({ name }) => {
    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const short_name = name.replace(/([a-z]+) .* ([a-z]+)/i, "$1 $2");
    return short_name.match(/\b\w/g).join("");
  };

  return (
    <>
      <Divider style={{ marginTop: 15, marginBottom: 15, paddingTop: isMobile ? 15 : 0 }} dashed />
      <div className="producer-container">
        <Avatar
          size="small"
          src={data?.producer?.picture}
          icon={<UserOutlined />}
          style={{ backgroundColor: "#f56a00" }}
        >
          <Initials name={data?.producer?.name} />
        </Avatar>

        <div className="producer">
          <div>
            <Text className="producer-name">
              <strong>Produtor:</strong>{" "}
              <StyledSpan onClick={() => setShowProducerModal(true)}>
                <span style={{ color: linkColor }}>{data?.producer?.name}</span>
              </StyledSpan>
            </Text>
          </div>
        </div>
      </div>
      {!isMobile && (
        <Modal
          visible={showProducerModal}
          title="Contacto do Produtor"
          onCancel={() => setShowProducerModal(false)}
          footer={[
            <CustomButton
              customStyle={customStyle}
              type="primary"
              key="back"
              onClick={() => setShowProducerModal(false)}
            >
              Fechar
            </CustomButton>,
          ]}
        >
          <ProducerContacts producer={data?.producer} linkColor={linkColor} />
        </Modal>
      )}
      {isMobile && (
        <Drawer
          title="Contacto do Produtor"
          placement="bottom"
          closable={true}
          onClose={() => setShowProducerModal(false)}
          visible={showProducerModal}
          footer={
            <div style={{ textAlign: "center" }}>
              <CustomButton
                customStyle={customStyle}
                type="primary"
                key="back"
                onClick={() => setShowProducerModal(false)}
              >
                Fechar
              </CustomButton>
              ,
            </div>
          }
        >
          <ProducerContacts producer={data?.producer} customStyle={customStyle} linkColor={linkColor} />
        </Drawer>
      )}
    </>
  );
};

export default Producer;
